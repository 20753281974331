<!--
 * @Descripttion: 头部组件
 * @version: 
 * @Author: Ran
 * @Date: 2022-08-09 19:15:17
 * @LastEditors: Ran
 * @LastEditTime: 2022-10-25 14:36:28
-->


<template>
    <nav class="navbar navbar-expand-lg navbar-light vw-100" 
        :style="{ background:navIsLucency?'transparent !important': 'var(--theme) !important'}">

        <button class="navbar-toggler" type="button" data-toggle="collapse" 
            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="container h-100">
            <router-link to="/" class="navbar-brand">
                <img src="@/static/logo.png" style="width:23.8rem;height:6rem" alt="" />
            </router-link>
            <div id="navbarSupportedContent" v-if="navMenu"
                class="collapse navbar-collapse h-100">
                <ul class="navbar-nav w-100 com-row-between h-100">
                    <li v-for="(item,index) in nav" :key="index" 
                        class="nav-item">
                        <router-link class="nav-link" :to="item.path">{{item.name}}</router-link>
                        <div class="com-flex com-row-center active">
                            <div class="line" :style="{width: item.path==presentActiveRoute?'30%':0}"></div>
                        </div>
                    </li>
                    <li v-if="$config.name=='相如'" class="nav-item">
                        <a href="http://en.paxrgc.com/">英文版</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>


<script>
import config from '@/config/index.js';
export default {
    name: 'Header',
    components: {},
    props: {
        // 导航栏是否透明
        navIsLucency:{ type: Boolean, default: false },
        // 是否显示右侧菜单
        navMenu: { type: Boolean, default: false },
        // 当前页面路由
        presentActiveRoute:{ type: String, default: '/' }
    },

    data() {
        return {
            nav: config.nav
        }
    },

    computed: {},
    watch: {},

    created() {},
    mounted() {},

    methods: {}
}
</script>


<style scoped lang="scss">
    .navbar{
        height: 9rem;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        transition: background .3s;
        .container{
            /* item */
            li{
                position: relative;
                a{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: var(--white) !important;
                    font-size: 1.7rem;
                }
                /* line */
                .active{
                    width: 100%;
                    position: absolute;
                    left: 0;
                    bottom: 1rem;
                    .line{
                        width: 0;
                        height: 3px;
                        border-radius: 3px;
                        background: var(--white);
                        transition: width .1s;
                    }
                }
            }
        }
    }
    @media (min-width: 1200px){
        .navbar{
            padding: .5rem 5rem;
            .navbar-brand{
                margin-right: 8rem;
            }
            .nav-link{
                padding: 0 1.5rem 0 1.5rem !important;
            }
		}
    }
</style>