/*
 * @Descripttion: 封装请求
 * @version:
 * @Author: Ran
 * @Date: 2022-08-16 10:21:04
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-22 10:25:09
 * https://blog.csdn.net/HH18700418030/article/details/99470605
 */



// 首先引入request文件需要的依赖
import axios from 'axios'
import qs from 'qs';
import { Notification } from 'element-ui'
import router from '../router'
import config from '../config/index'
import { DecryptString } from "@/common/encryption";


// 设置默认请求头
axios.defaults.headers['app_id'] = config.app_id
// axios.defaults.headers['sub_app_id'] = config.sub_app_id
axios.defaults.headers['source'] = 'phone'


// 创建axios实例
const service = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API,
    baseURL: config.baseUrl,
    // 跨域请求时发送cookie
    // withCredentials: true,
    // 超时时间
    timeout: 10000,
    // 请求头配置
    headers: {
        'Content-Type': 'application/json;charset=utf-8',
    }
})


// request拦截器
service.interceptors.request.use(config => {
    // 动态设置请求头
    const json = config?.custom?.json
    config.headers['Content-Type'] = json ? 'application/x-www-form-urlencoded' : 'application/json'
    // 动态设置token
    var isToken = false
    if (config.method == 'get') isToken = config.params && config.params.token ? true : false
    if (config.method == 'post') isToken = config.custom && config.custom.token ? true : false
    config.headers['Authorization'] = isToken ? localStorage.getItem("Authorization") : ''
    // 请求时断网
    if (!navigator.onLine) console.log('断网了')
    // 返回
    return config
}, error => {
    Promise.reject(error)
})



// 响应拦截器
service.interceptors.response.use(res => {
    // console.log('成功',res);
    const { code } = res.data
    if (code == 0) {
        return res.data && res?.config?.url != "/open/sys-config/global-info" ? DecryptString(res.data.data) : res.data.data;
        // return res.data;
    } else {
        return Promise.reject(res)
    }
}, error => {
    const { code } = error.response.data
    if (code == 2000) {
        Notification({ type: 'error', message: '您还未登陆或登陆已过期', duration: 1500 })
        setTimeout(() => {
            router.push('/login')
        }, 1500);
    }
    else if (code == 2007) {
        // token即将过期
    }
    else if (code == 3005) {
        // 未绑定手机号码
    }
    else {
        Notification({ type: 'error', message: error.response.data.message })
    }
    return Promise.reject(error)
})



// export default service
export function request({ method, url, params, config }) {
    let data
    if (method == 'GET') {
        data = { params: params }
    }
    if (method == 'POST') {
        const json = config?.custom?.json
        data = json ? qs.stringify(params) : params
    }
    return new Promise((resolve, reject) => {
        service[method.toLowerCase()](url, data, config).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}