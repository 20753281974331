export default {
    // 解密aesKey
    aesKey: "uwTZwrCZYh7zKROK",


    // 测试环境
    // baseUrl: 'https://ggfw.smarttourisms.com/api/',
    // authUrl: 'https://ggfw.smarttourisms.com/auth/user/',
    // app_id: 'qoUVUKVpIAHnqULxAOjv',
    // sub_app_id: 'qoUVUKVpIAHnqULxAOjv',
    // PROJECT: 'TEST',
    // publicPath: '',
    // englishName: 'YUAN SHENG XINXI',
    // name: '源生',
    // copyright: '',


    // 相如故城
    baseUrl: 'https://www.paxrgc.com/api',
    authUrl: 'https://xrgc.smarttourisms.com/auth/user',
    app_id: 'YpXwBOUJoxjpFMoOLiKh',
    PROJRCT: 'XRGC',
    publicPath: '',
    englishName: 'XIANG RU GUCHENG',
    name: '相如',
    copyright: '蓬安相如旅游开发有限责任公司',


    // 兴文石海
    // baseUrl: 'https://xhshgw.xwshtxq.com/api',
    // authUrl: 'https://xhshgw.xwshtxq.com/auth/user',
    // app_id: 'VpzLBbXwzCHCszCDkkSz',
    // PROJECT: 'XWSH',
    // publicPath: '',
    // englishName: 'XING WEN SHIHAI',
    // name: '石海',
    // copyright: '',


    // 兴文僰王山
    // baseUrl: 'https://bwsgw.xwshtxq.com/api',
    // authUrl: 'https://bwsgw.xwshtxq.com/auth/user',
    // app_id: 'ZJUnHAHQgDAdUOiCnCzF',
    // PROJECT: 'XWBWS',
    // publicPath: '',
    // englishName: 'XING WEN BOWANGSHAN',
    // name: '僰王山',
    // copyright: '',


    nav: [
        { name: '首页', path: '/' },
        { name: '初识相如', path: '/general' },
        { name: '感知相如', path: '/media' },
        { name: '智游相如', path: '/resource' },
        { name: '旅游资讯', path: '/news' },
        { name: '旅游活动', path: '/activity' },
        { name: '旅游指南', path: '/travelguide' },
        // { name: '个人中心', path: '/mine' }
    ],
    footer: [
        {
            name: '初识相如', path: '/general',
            list: [
                // 这里动态渲染
            ]
        },
        {
            name: '感知相如', path: '/media',
            list: [
                { name: '美景图库', path: '/media/image' },
                { name: '精彩视频', path: '/media/video' },
                { name: '虚拟体验', path: '/media/video?type=liveAction' },
                { name: '旅游攻略', path: '/media/strategy' },
                { name: '旅游路线', path: '/media/line' },
                { name: '景区手绘', path: '/media/gis' },
            ]
        },
        {
            name: '智游相如', path: '',
            list: [
                { name: '热门景点', path: '/list?type=SPOT' },
                { name: '特色美食', path: '/list?type=FOOD' },
                { name: '酒店住宿', path: '/list?type=HOTEL' },
                { name: '旅游特产', path: '/list?type=SPECIALTY' },
                { name: '休闲娱乐', path: '/list?type=RECREATION' },
            ]
        },
        {
            name: '旅游资讯', path: '/news',
            list: [
                // 这里动态渲染
            ]
        },
        {
            name: '旅游活动', path: '/activity',
            list: []
        },
        {
            name: '旅游指南', path: '/travelguide',
            list: [
                { name: '天气查询', path: '/travelguide/weather' },
                { name: '在线投诉', path: '/travelguide/complaint' },
                { name: '出行指南', path: '/travelguide/trip' },
            ]
        },
        // {
        //     name:'个人中心', path:'/mine' ,
        //     list: [
        //         { name:'注册', path:'/login' },
        //         { name:'登陆', path:'/login' },
        //         { name:'个人资料', path:'/mine' },
        //         { name:'安全设置', path:'' },
        //     ]
        // }
    ],


    // 分页
    paging: {
        page: 1,
        limit: 9
    }
}