<!--
 * @Descripttion: 首页
 * @version: 
 * @Author: Ran
 * @Date: 2022-08-09 19:05:05
 * @LastEditors: Ran
 * @LastEditTime: 2022-10-25 14:58:13
-->



<template>
  	<div class="content">
		<!-- 全屏实景配置 -->
		<section class="iframe" v-if="liveActionList.length">
			<iframe id="iframe" class="vw-100 vh-100" :src="liveActionList[0].url" frameborder="0"> </iframe>
			<div class="custom">
				<img width="50%" class="welcome animate__animated animate__flipInX animate__delay-2s" 
					src="@/static/home/welcome.png" alt="" /> 
				<div style="margin-top:4rem">
					<el-button @click="scorll" type="primary" plain class="animate__animated animate__flipInX animate__delay-2s">
						开始探索
					</el-button>
					<el-button v-if="$config.PROJECT=='XWSH'|| $config.PROJECT=='XWBWS'"
						@click="goGroup" type="success" plain class="animate__animated animate__flipInX animate__delay-2s">
						集团官网
					</el-button>
				</div>
			</div>
		</section>
		<section v-else id="carouselExampleControls" class="carousel slide" data-ride="carousel" data-interval="3000">
			<div class="carousel-inner">
				<div v-for="(item,index) in 3" :key="index" class="carousel-item active">
					<img src="@/static/bg/h-home.png" class="d-block vw-100 vh-100" alt="">
				</div>
			</div>
			<div class="custom">
				<img width="50%" class="welcome animate__animated animate__flipInX" 
					src="@/static/home/welcome.png" alt="" /> 
				<div style="margin-top:4rem">
					<el-button @click="scorll" type="primary" plain class="animate__animated animate__flipInX">开始探索</el-button>
					<el-button v-if="$config.PROJECT=='XWSH'|| $config.PROJECT=='XWBWS'"
						@click="goGroup" type="success" plain class="animate__animated animate__flipInX">
						集团官网
					</el-button>
				</div>
			</div> 
		</section>


		<!-- 热门活动 -->
		<section class="activity">
			<div class="container">
				<div class="com-flex com-row-between">
					<h2 class="com-font-20 com-font-bold">热门活动</h2>
					<div class="com-flex com-cursor-pointer" @click="skip('/activity')">
						<span class="com-m-r-4 com-font-14 com-minor-color">查看更多</span>
						<img src="@/static/right.png" width="15" height="15" alt="">
					</div>
				</div>
				<div class="row">
					<div v-for="(item,index) in hotActivityList" :key="index" class="col col-12 col-lg-6 col-xl-4">
						<div class="item com-cursor-pointer" 
							@mouseenter="activityCurrent=index" @mouseleave="activityCurrent=null">
							<h5 class="com-font-18 com-font-bold com-line-1">{{item.title}}</h5>
							<p class="com-m-t-14 com-font-12 com-minor-color">活动时间:{{item.startDate}}~{{item.endDate}}</p>
							<img :class="['com-m-t-20',index==activityCurrent?'animate__animated animate__pulse':null]" 
								:src="item.logo" width="100%" style="height:25.5rem;object-fit:cover" alt="" />
							<p class="com-m-t-20 com-font-14 com-line-2">{{item.introduce?item.introduce:'暂无活动简介'}}</p>
							<div class="text-center">
								<button type="button" class="com-m-t-24 btn btn-outline-secondary" @click="goActivityDetail(item.id)">了解更多</button>
							</div>
						</div>
					</div>
					<div v-if="hotActivityList.length==0" class="w-100 com-flex com-row-center">
						<el-empty :image-size="200"></el-empty>
					</div>
				</div>
			</div>
		</section>


		<!-- 智游景区 -->
		<section class="play">	
			<div class="h-100 container com-flex com-flex-col com-row-center">
				<h2 class="com-font-20 com-font-bold">智游景区</h2>
				<p class="com-m-t-30 com-font-14 text-center" 
					style="line-height:28px" 
					v-html="play_text">	
				</p>
				<div class="w-100 row">
					<div v-for="(item,index) in plays" :key="index"
						@click="skip(item.path)"
						@mouseenter="playCurrent=index" @mouseleave="playCurrent=null"
						class="col com-m-b-20 com-flex com-flex-col com-row-center com-cursor-pointer">
						<img :src="item.icon" style="width:8rem;height:8rem" :class="[index==playCurrent?'animate__animated animate__flipInY':null]" />
						<span :class="['com-m-t-10 com-font-14',index==playCurrent?'animate__animated animate__flipInY':null]">{{item.name}}</span>
					</div>
				</div>
			</div>
		</section>


		<!-- 实景展播 -->
		<section class="liveAction">
			<div class="container">
				<div class="com-flex com-row-between">
					<h2 class="com-font-20 com-font-bold">实景展播</h2>
					<div class="com-flex com-cursor-pointer" @click="skip('/media/video?type=liveAction')">
						<span class="com-m-r-4 com-font-14 com-minor-color">查看更多</span>
						<img src="../static/right.png" width="15" height="15" alt="">
					</div>
				</div>
				<div class="row">
					<div v-for="(item,index) in liveActionList" :key="index" 
						class="col col-12 col-lg-6 col-xl-4">
						<div class="com-flex com-flex-col com-cursor-pointer item"
							@mouseenter="liveActionCurrent=index" @mouseleave="liveActionCurrent=null"
							@click="externalLinks(item.url)">
							<img :class="[index==liveActionCurrent?'animate__animated animate__flipInY':null]"
								:src="item.logo" width="100%" style="object-fit:cover;height:28.5rem" alt="" />
							<div class="w-100 com-line-1 text-left com-p-20 com-font-bold title">{{item.title}}</div>
							<img class="play-icon" src="@/static/play.png" width="68" height="68" alt="" />
						</div>
					</div>
					<div v-if="liveActionList.length==0" class="w-100 com-flex com-row-center">
						<el-empty :image-size="200"></el-empty>
					</div>
				</div>
			</div>
		</section>


		<!-- 友情链接 -->
		<section class="friendship">
			<div class="h-100 container com-flex com-flex-col">
				<h2 class="com-font-20 com-font-bold">友情链接</h2>
				<div class="row">
					<div v-for="(item,index) in friendships" :key="index"
						class="col com-flex com-row-center com-cursor-pointer item"
						@click="externalLinks(item.url)"
						@mouseenter="friendshipCurrent=index" @mouseleave="friendshipCurrent=null">
						<img :class="[index==friendshipCurrent?'animate__animated animate__pulse':null]"
							:src="item.icon" width="190" height="68" />
					</div>
				</div>
			</div>	
		</section>
	</div>
</template>


<script>
import configData from '@/config/data.js'
import { liveActionList,hotActivity } from '@/request/api.js'
import { DecryptString } from "@/common/encryption";
import config from "@/config/index";
export default {
	name:'Home',
	components: {},
	props: {},
	data() {
		return {
			// 热门活动
			hotActivityList: [],
			activityCurrent: null,
			// 智游景区
			play_text: configData.play_text,
			plays: [
				{ icon:require('@/static/home/play/play1.png'), name:'游在相如', path:'/list?type=SPOT' },
				{ icon:require('@/static/home/play/play2.png'), name:'住在相如', path:'/list?type=HOTEL' },
				{ icon:require('@/static/home/play/play3.png'), name:'吃在相如', path:'/list?type=DINING' },
				{ icon:require('@/static/home/play/play4.png'), name:'购在相如', path:'/list?type=SHOPPING' },
				{ icon:require('@/static/home/play/play5.png'), name:'娱在相如', path:'/list?type=RECREATION' },
			],
			playCurrent: null,
			// 实时展播
			liveActionList: [],
			liveActionCurrent: null,
			// 友情链接
			friendships: configData.friendships,
			friendshipCurrent: null,
		}
	},

	computed: {},
	watch: {},

	created() {},
	mounted() {
		// 获取实景展播列表
		this.getLiveActionList();
		// 获取热门活动
		this.getHotActivity();
	},

	methods: {
		// 实景展播
		getLiveActionList(){
			liveActionList().then(res=>{
				this.liveActionList = []
				res.map((v,i)=>{
					if( i<3) this.liveActionList.push(v)
				})
				console.log('实景展播',this.liveActionList)
			}).catch(err=>{})
		},


		// 跳转
		skip(path){
			this.$router.push(path); 
		},


		// 热门活动
		getHotActivity(){
			hotActivity().then(res=>{
				this.hotActivityList = []
				res.map((v,i)=>{
					if( i<3) this.hotActivityList.push(v)
				})
			}).catch(err=>{})
		},
		// 去活动详情页
		goActivityDetail(id){
			this.$router.push({ 
				path:'/list/detail',
				query: {
					type: 'ACTIVITY',
					id: id
				}
			})
		},


		// 外部链接
		externalLinks(url){
			if( url ){
				window.open(url)
			}else{
				this.$notify({ type: 'warning', message: '暂无外部链接地址' })
			}
		},


		// 页面滚动
		scorll(){
			$('body,html').animate({
				scrollTop: document.documentElement.clientHeight + 10
			},300)
		},

		// 去集团官网(兴文)
		goGroup(){
			location.href = 'https://xwshtxq.com/#/index'
		}
	}
}
</script>


<style scoped lang="scss">
	.iframe,.carousel{
		position: relative;
		overflow: hidden;
		.custom{
			width: 100%;
			position: absolute;
			top: 35%;
			left: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}


	/* 热门活动 */
	.activity{
		padding: 6rem 0;
		.row{
			margin-top: 3rem;
			.item{
				background: #EEEEEE;
				padding: 3.5rem 3rem;
				transition: background .6s;
				border-radius: 6px;
				img{
					border-radius: 6px;
				}
				&:hover{
					background: var(--theme);
					color: var(--white);
					p{
						color: var(--white);
					}
					.btn{
						border: 1px solid var(--white);
						color: var(--white);
					}
				}
				.btn{
					font-size: 14px;
					padding: 8px 16px;
				}
			}
		}
		
	}


	/* 智游景区 */
	.play{
		height: 590px;
		background: url('../static/home/play/bg.png') no-repeat center center;
		background-size: cover;
		color: var(--white);
		.row{
			margin-top: 6rem !important;
		}
	}

	/* 实景展播 */
	.liveAction{
		padding: 6rem 0;
		.row{
			.item{
				margin-top: 3rem;
				position: relative;
				.title{
					background: #EEEEEE;
					transition: background .5s;
				}
				.play-icon{
					position: absolute;
					top: 50%;
					left: 50%;
					margin-left: -34px !important;
					margin-top: -64px !important;
					z-index: 100;
				}
				&:hover{
					.title{
						background: var(--theme);
						color: var(--white);
					}
				}
			}
		}
	}

	/* 友情链接 */
	.friendship{
		width: 100%;
		height: 590px;
		background: url('../static/home/friendship/bg.png') no-repeat center center;
		background-size: cover;
		.container{
			margin-top: -8rem;
			padding-bottom: 10rem !important;
			justify-content: flex-end;
			.row{
				margin-top: 5rem !important;
				.item{
					margin-top: 3rem;
				}
			}
		}
	}
</style>
