import router from './index';
import { systemSeting } from '@/request/api.js';
import config from '@/config/index';
import { AesEncrypt } from "@/common/encryption";

router.beforeEach((to, from, next) => {
    if(sessionStorage.getItem("SYSTEMSETINFO")){
        next();
    }else{
        // 获取项目配置
        systemSeting().then(res=>{
            console.log("项目配置",res);
            sessionStorage.setItem("SYSTEMSETINFO", AesEncrypt(JSON.stringify(res),config.aesKey))
            next();
        })
    }
})
