<!--
 * @Descripttion: Footer组件
 * @version: 
 * @Author: Ran
 * @Date: 2022-08-09 19:15:17
 * @LastEditors: Ran
 * @LastEditTime: 2022-11-01 09:58:46
-->



<template>
    <footer>
        <div class="footer">
            <div class="container">
                <div class="row">
                    <div v-for="(tem,ind) in footer" :key="ind" 
                        class="col-4 col-sm-3 col-md tem">
                        <p class="text-center">
                            <router-link :to="tem.path">{{tem.name}}</router-link>
                        </p>
                        <div class="com-m-t-20 com-flex com-row-center">
                            <div class="line"></div>
                        </div>
                        <ul v-if="tem.list.length!=0">
                            <li v-for="(item,index) in tem.list" :key="index" 
                                class="text-center">
                                <router-link :to="item.path">{{item.name}}</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="com-font-14 com-assist-color beian">
            <div class="container com-flex com-flex-col com-row-center">
                <div v-if="$config.PROJRCT=='XRGC'" class="com-m-b-10 com-flex">
                    <a target="_blank" href="http://www.tsichuan.com/">四川省旅游资讯网</a>
                    <a target="_blank" href="http://wlt.sc.gov.cn/">四川省文旅厅官网</a>
                    <a target="_blank" href="http://www.nanchong.gov.cn/wgj/">南充市文化广播电视和旅游局官网</a>
                    <a target="_blank" href="http://www.nctravel.cn/">南充旅游资讯网</a>
                </div>
                <div class="com-flex">
                    <span class="com-m-r-20">版权所有：{{$config.copyright}}</span>
                    <span class="com-m-r-20">技术支持：成都科分衍生科技有限公司</span>
                    <a class="com-font-14" target="_blank" href="https://beian.miit.gov.cn">蜀ICP备2022022333号-1</a>
                </div>
            </div>
        </div>
    </footer>
</template>



<script>
import { projectGeneral,newsClass,activityClass } from '@/request/api.js'
import config from '@/config/index.js'
export default {
    name: 'Footer',
    components: {},
    props: {},

    data() {
        return {
            footer: config.footer
        }
    },
    
    computed: {},
    watch: {},

    created() {},
    mounted() {
        // 项目概况
        this.getProjectGeneral()
        // 资讯分类
        this.getNewsClass()
        // 活动分类
        this.getActivityClass()
    },

    methods: {
        // 项目概况
        getProjectGeneral(){
            let index = 0
            config.footer.map((v,i)=>{
                if( v.path == '/' ){
                    index = i
                }
            })
            projectGeneral().then(res=>{
                console.log('项目概况',res)
                config.footer[index].list = []
                res.map((v, i) => {
                    if (i < 5) {
                        config.footer[index].list.push({
                            name: v.title,
                            path: '/general?current='+i
                        })
                    }
                })
            }).catch(err=>{})
        },

        // 旅游资讯
        getNewsClass(){
            let index = 0
            config.footer.map((v, i) => {
                if( v.path == '/news' ){
                    index = i
                }
            })
            newsClass().then(res=>{
                console.log('资讯分类',res)
                config.footer[index].list = []
                res.map((v,i)=>{
                    if( i<4 ){
                        config.footer[index].list.push({
                            name: v.name,
                            path: '/news?code='+v.code
                        })
                    }
                })
            }).catch(err=>{})
        },

        // 活动分类(大类)
        getActivityClass(){
            activityClass().then(res=>{
                console.log('活动分类',res)
            }).catch(err=>{})
        }
    }
}
</script>



<style scoped lang="scss">
    .footer{
        background: #1F1F1F;
        a{
            color: #DFDFDF;
            transition: color .5s;
            &:hover{
                color: var(--white) !important;
            }
        }
        .row{
            padding: 6rem 0 !important;
            .line{
                width: 56px;
                height: 1px;
                background: #3B3B3B;
            }
            ul{
                margin-top: 2rem !important;
                li{
                    margin: 5px 0 !important;
                    a{
                        font-size: 14px;
                        color: #999999;
                    }
                }
            }
            @media (max-width: 576px){
                .tem {
                    margin-top: 3rem;
                }
            }
        }
    }
    /* 备案 */
    .beian{
        background: #000;
        padding: 3.2rem 0;
        a{
            font-size: 14px;
            margin-right: 1rem;
            color: #999;
            &:hover{
                color: #fff;
            }
        }
    }
</style>
