/*
 * @Descripttion: 主文件
 * @version: 
 * @Author: Ran
 * @Date: 2022-08-09 19:05:05
 * @LastEditors: Ran
 * @LastEditTime: 2022-10-25 14:37:01
 */


import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './router/router.permission'

// 引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


Vue.config.productionTip = false


// 引入jQuery、bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'


// font-awesome
import 'font-awesome/css/font-awesome.min.css';


// 主题
import './assets/css/theme.css';


// animate
// 高版本引入方式
import 'animate.css'
// 低版本引方式
// import animate from 'animate.css'
// Vue.use(animate)



// 全局配置
import config from './config/index.js'
Vue.prototype.$config = config



new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
