/*
 * @Descripttion: 路由
 * @version: 
 * @Author: Ran
 * @Date: 2022-08-09 19:05:05
 * @LastEditors: Ran JiangLai
 * @LastEditTime: 2022-09-13 21:48:23
 */


import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'


Vue.use(VueRouter)


const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView,
		meta:{
			keepAlive: true
		}
	},
	// 概况
	{
		path: '/general',
		name: '初识相如',
		component: () => import('../views/General.vue'),
		meta:{
			keepAlive:true
		}
	},

	
	// 感知僰王山
	{
		path: '/media',
		name: '感知僰王山',
		component: () => import('@/views/Media/Index.vue'),
		meta:{
			keepAlive:true,
		}
	},
	{
		path: '/media/image',
		name: '美景图库',
		component: () => import('@/views/Media/Image.vue'),
		meta:{
			keepAlive:true
		}
	},
	{
		path: '/media/video',
		name: '精彩视频',
		component: () => import('@/views/Media/Video.vue'),
		meta:{
			keepAlive:true
		}
	},
	{
		path: '/media/strategy',
		name: '旅游攻略',
		component: () => import('@/views/Media/Strategy.vue'),
		meta:{
			keepAlive:true
		}
	},
	{
		path: '/media/detail',
		name: '攻略详情',
		component: () => import('@/views/Media/StrategyDetail.vue'),
		meta:{
			keepAlive:true
		}
	},
	{
		path: '/media/line',
		name: '旅游线路列表',
		component: () => import('@/views/Media/Line.vue'),
		meta:{
			keepAlive:true
		}
	},
	{
		path: '/media/gis',
		name: '景区手绘',
		component: () => import('@/views/Media/Gis.vue'),
		meta:{
			keepAlive:true
		}
	},


	// 列表
	{
		path: '/list',
		name: '列表',
		component: () => import('@/views/List/List.vue'),
		meta:{
			keepAlive:true
		}
	},
	{
		path: '/list/detail',
		name: '详情',
		component: () => import('@/views/List/Detail.vue'),
		meta:{
			keepAlive:true
		}
	},


	// 旅游指南
	{
		path: '/travelguide',
		name: '旅游指南',
		component: () => import('../views/TravelGuide/Index.vue'),
		meta:{
			keepAlive:true
		}
	},
	{
		path: '/travelguide/weather',
		name: '天气',
		component: () => import('@/views/TravelGuide/Weather.vue'),
		meta:{
			keepAlive:true
		}
	},
	{
		path: '/travelguide/complaint',
		name: '投诉',
		component: () => import('@/views/TravelGuide/Complaint.vue'),
		meta:{
			keepAlive:true
		}
	},
	{
		path: '/travelguide/trip',
		name: '出行',
		component: () => import('@/views/TravelGuide/Trip.vue'),
		meta:{
			keepAlive:true
		}
	},


	// 资源
	{
		path: '/resource',
		name: '资源分类',
		component: () => import('../views/Resource/Class.vue'),
		meta:{
			keepAlive:true
		}
	},


	// 资讯
	{
		path: '/news',
		name: '资讯',
		component: () => import('@/views/News/News.vue'),
		meta:{
			keepAlive:true
		}
	},


	// 旅游活动
	{
		path: '/activity',
		name: '资讯',
		component: () => import('@/views/Activity/List.vue'),
		meta:{
			keepAlive:true
		}
	},


	// 我的
	{
		path: '/mine',
		name: '个人中心',
		component: () => import('@/views/Mine/Mine.vue'),
		meta:{
			keepAlive:true
		}
	},
	{
		path: '/login',
		name: '登陆',
		component: () => import('@/views/Mine/Login.vue'),
		meta:{
			keepAlive:true
		}
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		// 页面滚动到顶部
		$('body,html').animate({ scrollTop: 0 },300)
	}
})

export default router
