/*
 * @Descripttion: 请求接口
 * @version: 
 * @Author: Ran
 * @Date: 2022-08-16 10:21:20
 * @LastEditors: Ran
 * @LastEditTime: 2022-09-19 17:21:39
 */


import { request } from './request.js'



// 测试接口
export const test = (param) => {
    return request({
        method: 'GET',
        url: '/project-picture_tour/getConfig',
        params: param
    })
}


// 获取项目配置
export const systemSeting = (param) => {
    return request({
        method: 'GET',
        url: '/open/sys-config/global-info',
        params: param
    })
}



// 热门活动
export const hotActivity = (param) => { return request({ method: 'GET', url: '/project-activity/popularList', params: param }) }
// 活动分类
export const activityClass = (param) => { return request({ method: 'GET', url: '/project-activity/sourceList', params: param }) }
// 活动列表
export const activityList = (param) => { return request({ method: 'GET', url: '/project-activity/page', params: param }) }
// 活动详情
export const activityDetail = (param) => { return request({ method: 'GET', url: '/project-activity/detail', params: param }) }



// 实景展播(720)
export const liveActionList = (param) => { return request({ method: 'GET', url: '/project-index/getPanoramaConfig', params: param }) }



// 视频库
export const videoList = (param) => { return request({ method: 'GET', url: '/project-media/videoPage', params: param }) }
// 图库
export const imageList = (param) => { return request({ method: 'GET', url: '/project-media/picturePage', params: param }) }



// 景点
// 列表
export const spotList = (param) => { return request({ method: 'GET', url: '/project-spot/page', params: param }) }
// 详情
export const spotDetail = (param) => { return request({ method: 'GET', url: '/project-spot/detail', params: param }) }

// 酒店
// 列表
export const hotelList = (param) => { return request({ method: 'GET', url: '/project-hotel/page', params: param }) }
// 详情
export const hotelDetail = (param) => { return request({ method: 'GET', url: '/project-hotel/detail', params: param }) }

// 餐饮
// 列表
export const diningList = (param) => { return request({ method: 'GET', url: '/project-dining/page', params: param }) }
// 详情
export const diningDetail = (param) => { return request({ method: 'GET', url: '/project-dining/detail', params: param }) }

// 购物
// 列表
export const shoppingList = (param) => { return request({ method: 'GET', url: '/project-shopping/page', params: param }) }
// 详情
export const shoppingDetail = (param) => { return request({ method: 'GET', url: '/project-shopping/detail', params: param }) }

// 娱乐
// 列表
export const recreationList = (param) => { return request({ method: 'GET', url: '/project-recreation/page', params: param }) }
// 详情
export const recreationDetail = (param) => { return request({ method: 'GET', url: '/project-recreation/detail', params: param }) }

// 美食
// 列表
export const foodList = (param) => { return request({ method: 'GET', url: '/project-food/page', params: param }) }
// 详情
export const foodDetail = (param) => { return request({ method: 'GET', url: '/project-food/detail', params: param }) }

// 特产
// 列表
export const specialtyList = (param) => { return request({ method: 'GET', url: '/project-specialty/page', params: param }) }
// 详情
export const specialtyDetail = (param) => { return request({ method: 'GET', url: '/project-specialty/detail', params: param }) }



// 项目概况
export const projectGeneral = (param) => { return request({ method: 'GET', url: '/project-index/getOverviewConfig', params: param }) }
// 手绘图
export const gisConfig = (param) => { return request({ method: 'GET', url: '/project-index/getGisConfig', params: param }) }



// 旅游攻略
export const travelStrategy = (param) => { return request({ method: 'GET', url: '/project-raiders/page', params: param }) }
// 旅游攻略
export const travelStrategyDetail = (param) => { return request({ method: 'GET', url: '/project-raiders/detail', params: param }) }



// 资讯
// 分类
export const newsClass = (param) => { return request({ method: 'GET', url: '/project-news/typeList', params: param }) }
// 列表
export const newsList = (param) => { return request({ method: 'GET', url: '/project-news/page', params: param }) }
// 详情
export const newsDetail = (param) => { return request({ method: 'GET', url: '/project-news/detail', params: param }) }



// 路线
// 分类
export const lineClass = (param) => { return request({ method: 'GET', url: '/project-line/typeList', params: param }) }
// 列表
export const lineList = (param) => { return request({ method: 'GET', url: '/project-line/page', params: param }) }
// 路线详情
export const lineDetail = (param) => { return request({ method: 'GET', url: '/project-line/detail', params: param }) }



// 天气监测
export const weatherMonitor = (param) => { return request({ method: 'GET', url: '/project-index/getWeatherMonitor', params: param }) }
// 根据行政编码获取地区名称
export const region = (param) => { return request({ method: 'GET', url: '/project-index/regionInfo/' + param.id, params: param }) }



// 投诉
// 对象类型 
export const complaintType = (params) => { return request({ method: 'GET', url: '/project-complaint/types', params: params }) }
// 投诉对象
export const complaintObj = (params) => { return request({ method: 'GET', url: '/project-complaint/sourceList', params: params }) }
// 保存投诉
export const complaint = (params, config = { custom: { token: true } }) => { return request({ method: 'POST', url: '/project-complaint/save', params: params, config }) }


// 出行指南
export const travelGuide = (params) => { return request({ method: 'GET', url: '/project-index/travelGuide', params: params }) }



// 获取用户信息
export const userInfo = (params) => { return request({ method: 'GET', url: '/project-user/findUserInfo', params: { ...params, token: true } }) }
// 设置头像 
export const setAvatar = (params, config = { custom: { json: true, token: true } }) => { return request({ method: 'POST', url: '/project-user/bindAvatar', params: params, config }) }
// 修改设置昵称
export const setNickname = (params, config = { custom: { json: true, token: true } }) => { return request({ method: 'POST', url: '/project-user/bindNickname', params: params, config }) }
// 获取验证码
export const postCode = (params, config = { custom: { json: true } }) => { return request({ method: 'POST', url: '/project-user/sendCaptcha', params: params, config }) }
// 登录
export const phoneLogin = (params) => { return request({ method: 'POST', url: '/project-user/phoneLogin', params: params }) }