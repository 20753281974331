<!--
 * @Descripttion: 入口文件
 * @version: 
 * @Author: Ran
 * @Date: 2022-08-09 19:05:05
 * @LastEditors: Ran
 * @LastEditTime: 2022-09-19 17:22:41
-->


<template>
	<div id="app">
		<!-- Nav -->
		<HeaderView :navIsLucency="navIsLucency" :navMenu="navMenu" :presentActiveRoute="presentActiveRoute" />
		<!-- 顶部安全距离 -->
		<div v-if="($route.path!='/')&&navMenu" class="com-top-safety-distance"></div>
		<!-- 路由 -->
		<keep-alive>
			<router-view :key="$route.fullPath" />
		</keep-alive>
		<!-- 页脚 -->
		<FooterView v-if="navMenu" />
		<!-- 回到顶部 -->
		<el-backtop target=""></el-backtop>

		<!-- 相如故城电商小程序 -->
		<section v-if="$config.PROJRCT=='XRGC'" class="suspension-box">
			<div class="suspension-item">
				<span>电商小程序</span>
				<div class="position-box">
					<img src="./static/meituanErcode.png" alt="" />
				</div>
			</div>
			<div class="suspension-item">
				<span>咨询及投诉电话</span>
				<div class="position-box">
					<div style="white-space:nowrap">咨询电话: 0817-8990688</div>
					<div style="white-space:nowrap">投诉电话: 0817-8990788</div>
					<div style="white-space:nowrap">救援电话: 0817-8889655</div>
					<div style="white-space:nowrap">政务服务便民热线: 12345</div>
				</div>
			</div>
			<div class="suspension-item">
				<span>相如故城微信公众号二维码</span>
				<div class="position-box">
					<img src="./static/officialAccountErcode.jpg" alt="" />
				</div>
			</div>
		</section>
	</div>
</template>


<script>
import HeaderView from '@/components/HeaderView.vue';
import FooterView from '@/components/FooterView.vue';
export default {
    name: 'App',
    components: {
		HeaderView,
		FooterView
	},
    props: {},

    data() {
        return {
			// 头部导航栏是否透明(默认透明)
			navIsLucency: true,
			// 是否显示右侧菜单(默认不显示)
			navMenu: false,
			// 当前激活路由
			presentActiveRoute: 'null',
			// 窗口高度
			winHeight: 1080,
		};
    },

    computed: {},
    watch: {
		'$route':'getPath'
	},

    created() {},
	mounted () {
		// 获取路由
		this.getPath()
		// 获取窗口宽高
		this.getWindowInfo()
		window.onresize = () => {
			this.getWindowInfo()
		}
		// 滚动页面监听
		this.$nextTick(() => {
			window.addEventListener('scroll', this.handleScroll)
		})
    },

    methods: {
		// 获取路由
		getPath(){
			const route = this.$route.path
			// 导航栏是否透明以及是否显示右侧菜单
			if ( route == '/' || route == '/travelguide/complaint' || route == '/travelguide/weather' || route == '/login' ) {
				this.navIsLucency = true
				this.navMenu = false	
			} else {
				this.navIsLucency = false
				this.navMenu = true	
			}
			// 激活
			this.presentActiveRoute = route == '/' ? '/' : route.match('(/[a-z]+[^/]){1}')[0]
		},

		// 获取窗口宽高
		getWindowInfo(){
			this.winHeight = document.documentElement.clientHeight
		},

		// 页面滚动
		handleScroll(e) {
			const route = this.$route.path
			let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			if( route == '/' ){
				if (scrollTop == this.winHeight || scrollTop > this.winHeight) {
					this.navIsLucency = false
					this.navMenu = true
				}else{
					this.navIsLucency = true
					this.navMenu = false
				}
			}
		}
	}
}
</script>



<style lang="scss">
// 重置样式
@import './assets/css/reset.css';
// 自定义字体
@import './assets/fonts/fonts.css';
// 公共样式
@import './assets/css/styles.scss';


// element-ui
.el-notification__content{
	margin-top: 0 !important;
	font-family: Regular, '思源黑体', Avenir, Helvetica, Arial, sans-serif;
}
// 分页
.el-pagination{
	margin-top: 3rem;
	display: flex;
	justify-content: flex-end;
}
// loading
.el-loading-mask{
	background: transparent !important;
	display: flex;
	justify-content: center;
	font-family: Regular, '思源黑体', Avenir, Helvetica, Arial, sans-serif;
	.el-loading-spinner{
		border-radius: 6px;
		padding: 12px;
		background: rgba(0, 0, 0, 0.7) !important;
		width: 160px;
		.el-loading-text{
			margin-top: 6px !important;
			text-align: center;
		}
	}
}
// 空数据
.el-empty{
	padding: 0 !important;
}
//perview
.el-image-viewer__mask{
	opacity: 1 !important;
}
.el-image-viewer__close{
	display: none !important;
}


html,body{
	width: 100%;
	height: 100%;
	// overflow-x: hidden;
	::-webkit-scrollbar{
		width: 10px;
		height: 10px;
		background-color:#F5F5F5;
		display: none;
	}
	::-webkit-scrollbar-track{
		-webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
		border-radius: 5px;
		background-color:#F5F5F5;
	}
	::-webkit-scrollbar-thumb{
		border-radius: 5px;
		-webkit-box-shadow: inset 0 0 3px rgba(0,0,0,.3);
		background-color:rgba(0, 160, 233,1);
	}
}


html{
	font-size: 10px
}
@media (max-width: 1140px){
	html{
		font-size: 9px;
	}
}
@media (max-width: 992px){
	html{
		font-size: 8px;
	}
}
@media (max-width: 768px){
	html{
		font-size: 7px;
	}
}
@media (max-width: 576px){
	html{
		font-size: 6px;
	}
}


#app {
	width: 100%;
	height: 100%;
	font-family: Regular, '思源黑体', Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #333333;
	font-size: 16px;
}


// 根类名
.content{
	background: #F7F8F9;
}


// a标签
a{
	text-decoration: none !important;
	font-size: 16px;
	color: #282828;
}


// 开发用边框
.dev-b-r{
	border: 1px solid red;
}
.dev-b-g{
	border: 1px solid green;
}
.dev-b-t{
	border: 1px solid transparent;
}


// 字体加粗
.com-font-bold{
	font-family: Medium;
}


// 颜色
.com-main-color{
	color: #333333;
}
.com-minor-color{
	color: #666666;
}
.com-assist-color{
	color: #999999;
}
.com-inverse-color{
	color: #FFFFFF;
}
.com-high-color{
	color: #00A0E9;
}


// 鼠标手型
.com-cursor-pointer{
	cursor: pointer;
}


// 顶部安全距离
.com-top-safety-distance{
	height: 9rem !important;
}
// 面包屑导航
.com-breadcrumb{
	background: var(--white,#fff);
	padding: 2rem 0;
	.breadcrumb{
		background: var(--white,#fff);
		font-size: 15px;
		.breadcrumb-item{
			padding-left: 1rem !important;
			a{
				font-size: 15px;
			}
			&::before {
				padding-right: 1rem;
				content: ">";
			}
			&:nth-child(2){
				padding-left: 0 !important;
				&::before {
					display: none;
				}
			}
		}
		.active{
			color: #5b5e5f;
		}
	}
}
// 头部背景图片模块标题
.com-header-bg-title{
	font-size: 30px;
	color: var(--white,#FFF);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}


h2{
	font-size: 20px !important;
	font-family: Medium;
}


/* 悬浮 */
.suspension-box{
	position: fixed;
	bottom: 300px;
	right: 40px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	z-index: 99999;
	.suspension-item{
		width: 60px;
		background: #462724;
		backdrop-filter: blur(6px);
		font-size: 12px;
		color:#fff;
		cursor: pointer;
		padding: 8px 5px 0 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		position: relative;
		span{
			display: inline-block;
			padding-bottom: 8px;
			border-bottom: 1px solid rgb(110, 110, 110);
		}
		&:first-child{
			border-radius: 8px 8px 0 0;
		}
		&:last-child{
			border-radius: 0 0 8px 8px;
			span{
				border-bottom: 1px solid transparent;
			}
		}
		&:hover{
			.position-box{
				display: block;
			}
		}
		.position-box{
			display: none;
			position: absolute;
			right: 70px;
			top: 50%;
			transform: translate(0,-50%);
			background: #462724;
			backdrop-filter: blur(50px);
			padding: 1rem;
			border-radius: 8px;
			font-size: 14px;
			img {
				width: 20rem;
				height: 20rem;
				max-width: inherit !important;
			}
			&:after{
				content: '';
				width: 0;
				height: 0;
				border: 10px solid transparent;
				border-left: 10px solid #462724;
				position: absolute;
				top: 50%;
				transform: translate(0,-50%);
				right: -20px;
			}
		}
	}
}
</style>
