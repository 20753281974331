export default {
    // 相如故城
    play_text: `'相如故城'是西汉推动文化中国发展的'天下文宗'司马相如的出生地和生长地,<br/>
                在修复'司马相如'旧址的基础上,依托优势地貌,斥巨资复原打造而成。`,
    friendships: [
        { icon: require('../static/home/friendship/friendship1.png'), name: '途牛', url: 'https://nch.tuniu.com/' },
        {
            icon: require('../static/home/friendship/friendship2.png'), name: '飞猪',
            url: 'https://www.fliggy.com/?ttid=seo.000000574&seoType=origin'
        },
        { icon: require('../static/home/friendship/friendship3.png'), name: '去哪儿', url: 'https://www.qunar.com/' },
        { icon: require('../static/home/friendship/friendship4.png'), name: '美团', url: 'https://www.meituan.com/' },
        { icon: require('../static/home/friendship/friendship5.png'), name: '携程', url: 'https://vacations.ctrip.com/' },
    ],
    overview: [
        {
            bgColor: '#183542', name: '720全景',
            explain: `相如故城是司马相如的出生地和成长地,是古相如县县衙和古蓬州州府所在地,是嘉陵江畔唯一一座州县同治的太极太玄城。`,
            src: require('@/static/TravelGuide/tourguide-1.png'), path: '/media/video?type=liveAction'
        },
        {
            bgColor: '#00A0E9', name: '美景图库',
            explain: `景区位于四川东北部一南充市蓬安县锦屏镇,与“百牛渡江”“周子古镇”相互拥抱,沿嘉陵江形成了一条美丽的旅游带。`,
            src: require('@/static/TravelGuide/tourguide-2.png'), path: '/media/image'
        },
        {
            bgColor: '#4AC524', name: '景区手绘',
            explain: `司马相如故里位于四川省东部,嘉陵江中游幅员面积1332平方公里,总人口73万,嘉陵江蜿蜒蓬安89公里,
            孕育了深厚浓重的历史文化,形成了独具魅力的民俗风情,造就了自然生态的山水田园`,
            src: require('@/static/TravelGuide/tourguide-3.png'), path: '/media/gis'
        },
    ],


    // 兴文石海
    // play_text: `兴文石海位于四川省宜宾市兴文县境内，处于四川盆地与云贵高原过渡地带，<br/>总面积约156平方公里，
    //     是世界地质公园、国家级风景名胜区。`,
    // friendships: [
    //     { icon: require('../static/home/friendship/friendship1.png'), name:'途牛',
    //         url: 'https://www.tuniu.com/g9845/guide-0-0/' },
    //     { icon: require('../static/home/friendship/friendship3.png'), name:'去哪儿',
    //         url: 'https://piao.qunar.com/ticket/detail_2832463844.html' },
    //     { icon: require('../static/home/friendship/friendship4.png'), name:'美团',
    //         url: 'https://www.meituan.com/zhoubianyou/4207041/' },
    //     { icon: require('../static/home/friendship/friendship5.png'), name: '携程',
    //         url: 'https://you.ctrip.com/sight/xingwen3120/69564.html' },
    // ],
    // overview: [
    //     {   bgColor: '#183542', name: '720全景',
    //         explain: `兴文石海世界地质公园位于四川省南部兴文县，地理坐标为东经105°0′56″～105°09′46″，北纬28°08′54″～28°20′29″，
    //             地处四川盆地南缘山区，系乌蒙山北端的仙峰山、川云山脉，属四川盆地南部山地与云贵高原的过渡地带。`,
    //         src: require('@/static/TravelGuide/tourguide-1.png'), path: '/media/video?type=liveAction'
    //     },
    //     {   bgColor: '#00A0E9', name: '美景图库',
    //         explain: `兴文石海地质公园为中低山窄谷岩溶地貌，山体坡度大多在60°以上。公园内展布一系列单面山、环状山，群峰耸立，
    //             河流深切，地形起伏较大，地势北低南高，海拔429～1795m。`,
    //         src: require('@/static/TravelGuide/tourguide-2.png'), path: '/media/image'
    //     },
    //     {   bgColor: '#4AC524', name: '景区手绘',
    //         explain: `兴文石海常年受交替的大陆气团影响，属中亚热带湿润季风气候，气候温和，雨量充沛，无霜期长，四季分明，
    //             雨热同季，为地质公园内岩溶地貌的发育提供了良好的气候条件。`,
    //         src: require('@/static/TravelGuide/tourguide-3.png'), path: '/media/gis'
    //     },
    // ],


    // 兴文僰王山
    // play_text: `"僰（bó）王山，又名博望山。僰王山是古僰民族最重要的繁衍生息地之一，<br/>古名纶缚大囤、石头大寨，
    //     宋徽宗政和七年赐名为僰王山。此处竹林茂密，飞雾洞很壮观。`,
    // friendships: [
    //     { icon: require('../static/home/friendship/friendship1.png'), name: '途牛',
    //     url: 'https://www.tuniu.com/g1167774/guide-0-0/' },
    //     { icon: require('../static/home/friendship/friendship3.png'), name: '去哪儿',
    //     url: 'https://piao.qunar.com/ticket/detail_2794349633.html' },
    //     { icon: require('../static/home/friendship/friendship4.png'), name: '美团',
    //     url: 'https://www.meituan.com/zhoubianyou/42785289/' },
    //     { icon: require('../static/home/friendship/friendship5.png'), name: '携程',
    //     url: 'https://you.ctrip.com/sight/xingwen3120/49751.html' },
    // ],
    // overview: [
    //     {   bgColor: '#183542', name: '720全景',
    //         explain: `僰王山由南向北五条溪流，在山门沟谷中透迄穿行，形成数量众多，形状各异的瀑布群：
    //             有起伏跌宕，绵延数千米的梦溪叠瀑；有飞流直卜，如白龙出洞的龙泉瀑：
    //             有"大吼三声雨就来"的同声瀑；还有雨后四面八方皆是瀑布的宝盆谷围瀑……僰人无今，古寨留形，诱人神往。僰王山自古即为名山。`,
    //         src: require('@/static/TravelGuide/tourguide-1.png'), path: '/media/video?type=liveAction'
    //     },
    //     {   bgColor: '#00A0E9', name: '美景图库',
    //         explain: `僰王山是四川宜宾的一座古老的名山，位于宜宾兴文县城西20公里的晏阳镇，
    //             地处蜀南竹海和石海洞乡两大风景区之间，是四川省重点风景名胜区。`,
    //         src: require('@/static/TravelGuide/tourguide-2.png'), path: '/media/image'
    //     },
    //     {   bgColor: '#4AC524', name: '景区手绘',
    //         explain: `2000年2月，僰王山正式被四川省人民政府批为省级重点风景名胜区。名山沉睡，春风徐来，欣然而跻于名胜之林。`,
    //         src: require('@/static/TravelGuide/tourguide-3.png'), path: '/media/gis'
    //     },
    // ],
}